import React from "react"
import Sidebar from "./Sidebar"
import details1 from "../../../assets/images/services/services-details1.jpg"
import project2 from "../../../assets/images/projects/project2.jpg"
import charts from "../../../assets/images/services/charts.jpg"

const Contenido = () => {
  return (
    <section className="services-details-area ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="services-details-image">
              <img src={details1} alt="about" />
            </div>
            <div className="services-details-desc">
              <h3>Acerca de este servicio</h3>
              <p>
                Escritorio Digital es un servicio de Gestión Documental en la
                nube, que ayuda a las empresas a gestionar de forma eficiente,
                segura y centralizada los documentos, sin la necesidad de
                invertir en infraestructura costosa y que además incorpora
                herramientas que permiten trabajar de manera colaborativa,
                permitiendo la autonomía de nuestros clientes y usuarios, e
                incluso permite firmar documentos de forma electrónica, con
                firma simple (FES) y/o avanzada (FEA), sin necesidad de otro
                sistema de firmas. Por esta razón también es que rentabiliza el
                espacio físico, y habilita las herramientas para el teletrabajo.
              </p>

              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6">
                  <div className="image">
                    <img src={project2} alt="about" />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="content">
                    <h3>Principales características</h3>
                    <ul>
                      <li>Estructuración en base a carpetas</li>
                      <li>Categorización en base a etiquetas</li>
                      <li>Definición de palabras clave</li>
                      <li>Sistema de permisos</li>
                      <li>Notificaciones de cambios</li>
                      <li>Aprobación de documentos</li>
                      <li>Versionamiento</li>
                    </ul>
                  </div>
                </div>
              </div>
              <h3>Mayor Productividad</h3>

              <p>
                La productividad hace referencia a la relación existente entre
                la cantidad de productos que se han obtenido a través de un
                sistema de producción y los recursos que han sido utilizado para
                ello. Esta relación puede ser utilizada como ejemplo tanto en el
                área productiva como en el área administrativa, lo que
                finalmente se busca es la eficacia y la eficiencia de cada
                proceso.
              </p>
              <p>
                Existen estudios que demuestran que el retorno de la inversión
                mejora al utilizar como recurso la tecnología, mayor aún cuando
                es posible invertir en herramientas que pueden ayudar en los
                procesos específicos como lo son los gestores documentales,
                reduciendo los costes, aumenta la calidad del servicio y se
                logra una mejor respuesta a la necesidad de orden documental
                para la organización, con ello se demuestra el aumento de la
                productividad, viniendo a eliminar el vicio de algunos procesos
                que no permitían poder tener el control y tampoco poder llevar
                una medición de manera eficaz. Es precisamente esto lo que se
                logra con un gestor documental unifica los procedimientos para
                realizar todas la actividades de manera uniforme para toda la
                organización y poder cuantificar los procesos.
              </p>

              <h3>Caracteristicas generales de nuestros servicios</h3>

              <div className="row">
                <div className="col-lg-4 col-sm-6 col-md-6">
                  <div className="single-industries-serve-box">
                    <div className="icon">
                      <i className="flaticon-settings"></i>
                    </div>
                    API Rest
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6 col-md-6">
                  <div className="single-industries-serve-box">
                    <div className="icon">
                      <i className="flaticon-rocket"></i>
                    </div>
                    Serverless
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6 col-md-6">
                  <div className="single-industries-serve-box">
                    <div className="icon">
                      <i className="flaticon-tracking"></i>
                    </div>
                    High Availability
                  </div>
                </div>
              </div>

              <h3>Technologies That We Use</h3>
              <ul className="technologies-features">
                <li>
                  <span>JavaScript</span>
                </li>
                <li>
                  <span>Python</span>
                </li>
                <li>
                  <span>Java</span>
                </li>
                <li>
                  <span>C/CPP</span>
                </li>
                <li>
                  <span>PHP</span>
                </li>
                <li>
                  <span>Swift</span>
                </li>
                <li>
                  <span>C# (C- Sharp)</span>
                </li>
                <li>
                  <span>Ruby</span>
                </li>
                <li>
                  <span>SQL</span>
                </li>
              </ul>
              <div className="charts-image">
                <img src={charts} alt="about" />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-12">
            <Sidebar />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contenido
